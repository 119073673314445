import classNames from 'classnames';
import Hamburger from 'hamburger-react';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { MenuItem, OverlayMenu } from '../OverlayMenu';
import { useCustomEventTracker } from '@/analytics';
import { UserCenter } from '@/containers/UserCenter';
import { withClickEvent } from '@/hoc/with-click-event';
import { Navigation as NavigationType } from '@/types/navigation';

type NavbarProps = {
  navigation: NavigationType;
  userComponent?: React.ReactNode;
  actionsComponent?: React.ReactNode;
  transparent?: boolean;
};

const TrackNavLink = withClickEvent(NavLink);

export const Navbar: React.FC<NavbarProps> = ({ navigation, userComponent, actionsComponent, transparent = false }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const trackMenuClick = useCustomEventTracker();

  const menuItems: MenuItem[] = useMemo(() => {
    return navigation.map((item) => ({
      menuId: item.id.toString(),
      text: item.title,
    }));
  }, [navigation]);

  const onMobileMenuItemClicked = useCallback(
    (_menuItem: MenuItem, index: number) => {
      const menu = navigation[index];
      setIsMenuOpen(false);

      trackMenuClick({
        action: 'click',
        category: 'Category',
        label: menu.title,
      });

      if (menu && menu.path) {
        navigate(menu.path);
      }
    },
    [navigation, navigate, trackMenuClick],
  );

  return (
    <nav
      className={classNames(
        'px-1 md:px-4 py-2 md:py-4 fixed flex align-middle justify-between text-gray-200 md:text-sm md:font-medium top-0 w-full z-50',
        {
          'bg-black bg-opacity-75': !transparent,
        },
      )}
    >
      <div className="flex flex-1 md:justify-start items-center text-center">
        {/* Mobile */}
        <div className="md:hidden z-50">
          <Hamburger hideOutline toggled={isMenuOpen} size={20} onToggle={setIsMenuOpen} />
          {isMenuOpen && (
            <OverlayMenu
              items={menuItems}
              userComponent={
                <UserCenter
                  display="inline"
                  beforeLogin={() => setIsMenuOpen(false)}
                  beforeLogout={() => setIsMenuOpen(false)}
                />
              }
              onDismiss={() => setIsMenuOpen(false)}
              onMenuClicked={onMobileMenuItemClicked}
            />
          )}
        </div>

        <Link to="/" className="p-0 m-0">
          <img
            src="https://static.cubaflix.com/logo/cubaflix-logo-large.png"
            alt="Logo"
            className="w-20 mt-[-16px]"
            srcSet="https://static.cubaflix.com/logo/cubaflix-logo-large@2x.png 2x"
          />
        </Link>

        {/* Desktop */}
        <ul className="hidden md:flex flex-wrap ml-8 space-x-4 lg:space-x-8 select-none">
          {navigation.map((item) => (
            <li key={item.id}>
              <TrackNavLink
                to={item.path ?? '/'}
                end={item.path === '/'}
                className={({ isActive }) => {
                  return classNames('hover:text-brand-accent', {
                    'hover:text-brand-accent': !isActive,
                    'text-brand-accent font-bold': isActive,
                  });
                }}
                clickevent={{
                  action: 'click',
                  category: 'Category',
                  label: item.title,
                }}
              >
                {item.title}
              </TrackNavLink>
            </li>
          ))}
        </ul>
      </div>

      {/* Right panel */}
      <div className="flex flex-0 items-center gap-x-2">
        {actionsComponent}
        <div className="md:block sm:hidden hidden">{userComponent ?? <UserCenter display="menu" />}</div>
      </div>
    </nav>
  );
};
