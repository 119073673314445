import React from 'react';
import { Outlet } from 'react-router-dom';
import { AuthVerify } from '@/containers/AuthVerify';
import { InformCookieConsent } from '@/containers/InformCookieConsent';
import { InstallHotjar } from '@/containers/InstallHotjar';
import { InstallSentry } from '@/containers/InstallSentry';
import { PageViewAnalytics } from '@/containers/PageViewAnalytics';

export const Root = () => {
  return (
    <main>
      <Outlet />
      <AuthVerify />
      <InformCookieConsent />
      <PageViewAnalytics />
      <InstallSentry />
      <InstallHotjar />
    </main>
  );
};
