import React, { createContext, useCallback, useMemo } from 'react';
import useLocalStorage from '@/hooks/useLocalStorage';
import { isValidToken } from '@/lib/jwt';
import { AppAuth, Session } from '@/types/app';

export const AuthContext = createContext<AppAuth>({
  isAuthenticated: false,
  logout: () => void {},
  session: null,
  setUserSession: () => void {},
});

type AuthProviderProps = {
  children: React.ReactNode;
};

const guestSession: Session = {
  jwt: undefined,
  provider: 'none',
  user: undefined,
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [session, setSession] = useLocalStorage<Session>('app-auth', guestSession);

  const setUserSession = useCallback(
    (session: Session) => {
      if (isValidToken(session?.jwt)) {
        setSession(session);
      }
    },
    [setSession],
  );

  const logout = useCallback(() => {
    setSession(guestSession);
  }, [setSession]);

  const isAuthenticated = useMemo(() => {
    return isValidToken(session?.jwt) && !!session?.user?.id;
  }, [session?.jwt, session?.user?.id]);

  const value = {
    isAuthenticated,
    logout,
    session,
    setUserSession,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
