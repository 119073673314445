import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { AuthProvider } from '@/context/AuthContext';
import { CacheDuration } from '@/lib/constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // The time in milliseconds that unused/inactive cache data remains in memory.
      // When a query's cache becomes unused or inactive, that cache data will be garbage collected after this duration.
      cacheTime: CacheDuration.FIVE_MINUTES,
      refetchOnWindowFocus: false,
      // How fresh the data is?
      // The time in milliseconds after data is considered stale.
      // Avoid network requests for this amount of time after the first successful request.
      // As long as the query is fresh, data will always be read from the cache only.
      // If the query is stale, you will still get data from the cache, but a background fetch will happen.
      staleTime: CacheDuration.NO_CACHE,
    },
  },
});

function App() {
  const routes = createRoutesFromElements(AppRoutes);
  const router = createBrowserRouter(routes);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
