import React from 'react';
import { useAppRouter } from '@/hooks/useAppRouter';
import { Image as ImageType } from '@/types/catalog';

type ThumbnailProps = {
  image: ImageType;
  className?: string;
};

export const Thumbnail: React.FC<ThumbnailProps> = ({ image, className }) => {
  const router = useAppRouter();
  const { alternativeText, formats } = image;
  const thumbnailUrl = formats?.thumbnail.url ?? image.url;

  return (
    <picture>
      {/* {image.url && (
        <source media={'(min-width: 1536px)'} srcSet={router.resolveImageUrl(image.url)} type={image.mime} />
      )}
      {formats?.large && (
        <source
          media={'(min-width: 1280px)'}
          srcSet={router.resolveImageUrl(formats.large.url)}
          type={formats.large.mime}
        />
      )} */}
      {formats?.medium && (
        <source
          media={'(min-width: 1024px)'}
          srcSet={router.resolveImageUrl(formats.medium.url)}
          type={formats.medium.mime}
        />
      )}
      {formats?.small && (
        <source
          media={'(min-width: 768px)'}
          srcSet={router.resolveImageUrl(formats.small.url)}
          type={formats.small.mime}
        />
      )}
      <img src={router.resolveImageUrl(thumbnailUrl)} alt={alternativeText ?? ''} className={className} />
    </picture>
  );
};
