import React from 'react';
// import { LogOut as LogoutIcon } from 'react-feather';
import { UserIdentity } from '@/types/app';

export type UserLoggedInlineProps = {
  user?: UserIdentity;
  onLogout: () => void;
};

export const UserLoggedInline: React.FC<UserLoggedInlineProps> = ({ onLogout }) => {
  return (
    <div className="flex flex-row w-full h-full">
      {/* <span className="uppercase text-center inline-block align-middle">{user.name}</span> */}
      <button
        onClick={onLogout}
        className="inline-flex items-center rounded-md px-4 py-2 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        {/* <LogoutIcon className="w-8 h-8" /> */}
        <span className="p-1 uppercase">cerrar sesión</span>
      </button>
    </div>
  );
};
