/* eslint-disable prettier/prettier */
// prettier-ignore

/**
 * The paths listed here won't be tracked by analytics.
 */
export const ignorePaths = [
  // URl before redirecting to the watch
  '/play',

  // Callback URL after login.
  // It contains the authorization code.
  // Sensitive: It can be used to impersonate the user.
  '/auth',
];
