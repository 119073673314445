import { LoaderFunctionArgs, defer } from 'react-router-dom';
import { getContentDetails } from '../api/get-content-details';

type LoaderParams = {
  slug: string;
};

export default function ({ request, params }: LoaderFunctionArgs) {
  const slug = (params as LoaderParams).slug;
  const details = getContentDetails(slug, { signal: request.signal });

  return defer({ details });
}
