import { useQuery } from '@tanstack/react-query';
import { getContinueWatchingList } from '../api/get-continue-watching-list';
import { CacheDuration } from '@/lib/constants';
import { ContentWatch } from '@/types/watch';

export const useGetContinueWatchingList = () => {
  const queryKey = ['continue-watching-list'];
  return useQuery<ContentWatch[]>(queryKey, ({ signal }) => getContinueWatchingList({ signal }), {
    cacheTime: CacheDuration.NO_CACHE,
    refetchOnWindowFocus: true,
    staleTime: CacheDuration.NO_CACHE,
  });
};
