export const shouldEnableSentry = (): boolean => {
  return process.env.NODE_ENV === 'production' && !!process.env.REACT_APP_SENTRY_DSN;
};

/**
 * Set tracesSampleRate to 1.0 to capture 100%
 * of transactions for performance monitoring.
 * @returns
 */
export const getSentryTracesSampleRate = (): number => {
  const envSampleRate = process.env.REACT_APP_SENTRY_SAMPLE_RATE;

  if (envSampleRate && !Number.isNaN(envSampleRate)) {
    return +envSampleRate;
  }

  return 0.2;
};

export const getSentryTracingOrigins = (): string[] => {
  if (!process.env.REACT_APP_SERVICES_URL) {
    return [];
  }

  try {
    const apiUrl = new URL(process.env.REACT_APP_SERVICES_URL);
    // It will include the host + the port (no need for schema).
    // Eg.: http://localhost:1337 will be localhost:1337
    // Eg.: https://services.cubaflix.com will be services.cubaflix.com
    return [apiUrl.host];
  } catch {
    // Ignore any error parsing the URL,
    // and fallback to return empty.
  }

  return [];
};
