import React from 'react';
import { Route } from 'react-router-dom';
import { HomePage } from './features/home';
import { LandingPage } from './features/landing/pages/Landing';
import { Layout } from './Layout';
import { Protected } from './Protected';
import { Root } from './Root';
import { lazyImport } from './utils/lazyImport';
import { LazyLoader } from '@/components/LazyLoader';
import contentDetailsLoader from '@/features/content-details/loaders/content-details.loader';
import authenticateLoader from '@/features/login/loaders/authenticate.loader';
import { useSession } from '@/hooks/useSession';

const { LoginPage } = lazyImport(() => import('@/features/login'), 'LoginPage');
const { LoginRedirectPage } = lazyImport(() => import('@/features/login'), 'LoginRedirectPage');
const { NotFoundPage } = lazyImport(() => import('@/features/error-page'), 'NotFoundPage');
const { ServerErrorPage } = lazyImport(() => import('@/features/error-page'), 'ServerErrorPage');
const { PageDetailsPage } = lazyImport(() => import('@/features/page-list'), 'PageDetailsPage');
const { ContentDetailsPage } = lazyImport(() => import('@/features/content-details'), 'ContentDetailsPage');
const { WatchPage } = lazyImport(() => import('@/features/play'), 'WatchPage');
const { PlayPage } = lazyImport(() => import('@/features/play'), 'PlayPage');
const { SearchPage } = lazyImport(() => import('@/features/search'), 'SearchPage');

const HomePageSwitch = () => {
  const { isAuthenticated } = useSession();
  return isAuthenticated ? <HomePage /> : <LandingPage />;
};

export const AppRoutes = (
  <Route>
    <Route element={<Root />}>
      <Route path="/watch/:watchId" element={<Protected />}>
        <Route
          index
          element={
            <LazyLoader>
              <WatchPage />
            </LazyLoader>
          }
        />
      </Route>
      <Route path="/play/:contentId" element={<Protected />}>
        <Route
          index
          element={
            <LazyLoader>
              <PlayPage />
            </LazyLoader>
          }
        />

        <Route
          path=":videoId"
          element={
            <LazyLoader>
              <PlayPage />
            </LazyLoader>
          }
        />
      </Route>

      <Route path="/" element={<Layout />}>
        <Route index element={<HomePageSwitch />} />
        <Route
          path="login"
          element={
            <LazyLoader>
              <LoginPage />
            </LazyLoader>
          }
        />

        <Route
          path="auth/:providerName/redirect"
          loader={authenticateLoader}
          element={
            <LazyLoader>
              <LoginRedirectPage />
            </LazyLoader>
          }
        />

        <Route
          path="404"
          element={
            <LazyLoader>
              <NotFoundPage />
            </LazyLoader>
          }
        />

        <Route
          path="500"
          element={
            <LazyLoader>
              <ServerErrorPage />
            </LazyLoader>
          }
        />

        <Route
          path="busqueda"
          element={
            <LazyLoader>
              <SearchPage />
            </LazyLoader>
          }
        />

        <Route
          path="detalles/:slug"
          loader={contentDetailsLoader}
          element={
            <LazyLoader>
              <ContentDetailsPage />
            </LazyLoader>
          }
        />

        <Route
          path=":page/:slug"
          loader={contentDetailsLoader}
          element={
            <LazyLoader>
              <ContentDetailsPage />
            </LazyLoader>
          }
        />

        <Route
          path=":page"
          element={
            <LazyLoader>
              <PageDetailsPage />
            </LazyLoader>
          }
        />

        <Route
          path="*"
          element={
            <LazyLoader>
              <NotFoundPage />
            </LazyLoader>
          }
        />
      </Route>
    </Route>
  </Route>
);
