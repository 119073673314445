import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SearchBox as Search } from '@/components/SearchBox';
import { ROUTES, ROUTES_KEYS } from '@/lib/constants';

const searchPrefix = ROUTES[ROUTES_KEYS.SEARCH];

export const SearchBox: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState('');

  // In case accesed via URL,
  // take the query as the initial value.
  useEffect(() => {
    if (searchParams.has('q')) {
      setValue(searchParams.get('q') ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToSearch = useCallback(
    (searchText: string) => {
      if (searchText.trim().length !== 0) {
        if (location.pathname.startsWith(searchPrefix)) {
          setSearchParams({ q: searchText });
        } else {
          navigate(`${searchPrefix}?q=${searchText}`);
        }
      }
    },
    [location, navigate, setSearchParams],
  );

  const onChange = useCallback(
    (value: string) => {
      setValue(value);
      redirectToSearch(value);
    },
    [setValue, redirectToSearch],
  );

  return (
    <div>
      <Search debounceTime={500} value={value} onChange={onChange} onSubmit={redirectToSearch} />
    </div>
  );
};
