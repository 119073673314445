import classNames from 'classnames';
import React from 'react';

type UserUnknownMenuProps = {
  onLogin: () => void;
  uppercaseText?: boolean;
};

export const UserUnknownMenu: React.FC<UserUnknownMenuProps> = ({ onLogin, uppercaseText = false }) => {
  return (
    <button
      type="button"
      onClick={onLogin}
      className={classNames(
        'select-none border border-brand-accent bg-brand-accent text-gray-200 hover:text-gray-900 hover:bg-gray-100 hover:border-gray-100 focus:ring-2 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-1 lg:py-2 text-center w-full sm:w-auto hover:shadow-xl',
        { uppercase: uppercaseText },
      )}
    >
      <span>Acceder</span>
    </button>
  );
};
