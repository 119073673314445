import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect, useRef } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { getSentryTracesSampleRate, getSentryTracingOrigins, shouldEnableSentry } from '../../lib/sentry';

export const InstallSentry = () => {
  const enableSentry = useRef<boolean>(shouldEnableSentry());

  useEffect(() => {
    if (enableSentry.current) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.NODE_ENV,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            ),
            tracingOrigins: getSentryTracingOrigins(),
          }),
          new CaptureConsoleIntegration({
            levels: ['error'],
          }),
        ],
        release: process.env.REACT_APP_RELEASE_VERSION ?? 'unknown',
        tracesSampleRate: getSentryTracesSampleRate(),
      });
    }
  }, []);

  return null;
};
