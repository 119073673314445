import ReactGA from 'react-ga4';
import { AnalyticsEventsMap, CustomEvent } from './events';

export function useEventTracker<K extends keyof AnalyticsEventsMap>(event: K) {
  return function (data: AnalyticsEventsMap[K]) {
    ReactGA.event(event, data);
  };
}

export function useCustomEventTracker() {
  return function <T extends CustomEvent>(data: T) {
    ReactGA.event(data);
  };
}
