import React from 'react';
import { Devices } from '../../components/Devices';
import { Hero } from '../../components/Hero';
import { LoginInvitation } from '../../components/LoginInvitation';
import { ContentLists } from '../../containers/ContentLists';
import { useGetLanding } from '../../services/use-get-landing';
import { Seo } from '@/components/Seo';

export function LandingPage() {
  const { data } = useGetLanding();

  return (
    <div className="relative top-16 lg:-top-20">
      {data && <Seo metadata={data?.seo} />}

      <div className="mx-auto">
        <Hero />
        <Devices />
        <ContentLists lists={data?.contentLists ?? []} />
        <LoginInvitation />
      </div>
    </div>
  );
}
