import jwt_decode, { JwtPayload } from 'jwt-decode';
import React, { useEffect, useRef } from 'react';
import { useSession } from '@/hooks/useSession';
import { isValidToken } from '@/lib/jwt';

const getSafeTimeoutFromJwtExp = (exp?: number) => {
  const expires = exp ? new Date(exp * 1000) : new Date();
  const now = new Date();
  const timeout = expires.getTime() - now.getTime();

  // Browsers including IE, Chrome, Safari, and Firefox store the delay as a 32-bit signed integer internally.
  // This causes an integer overflow when using delays larger than 2,147,483,647 ms (about 24.8 days),
  // resulting in the timeout being executed immediately.
  // See: https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
  const expiresAt = timeout > 0x7fffffff ? 0x7fffffff : timeout;

  return expiresAt;
};

export const AuthVerify: React.FC = () => {
  const timeoutHandler = useRef<NodeJS.Timeout | null>(null);
  const { session, logout } = useSession();

  // This effect will be executed on:
  // - initialization (first time)
  // - every browser refresh
  // - every time the token changes (maybe directly in the local storage?)
  useEffect(() => {
    if (session?.jwt && !isValidToken(session?.jwt)) {
      logout();
    }
  }, [session?.jwt, logout]);

  // Create a timer to clear the session before it expires.
  // The token has the future expiration date in unix-time.
  // Only act if the token is valid with a valid expiration.
  useEffect(() => {
    // Check if there is a token to watch for.
    if (session?.jwt && isValidToken(session?.jwt)) {
      try {
        const exp = jwt_decode<JwtPayload>(session?.jwt).exp;
        const timeout = getSafeTimeoutFromJwtExp(exp);

        timeoutHandler.current = setTimeout(() => {
          logout();
        }, timeout);
      } catch {
        timeoutHandler.current = null;
      }
    }

    // If the token changes, this function will re-run.
    // Before the next execution, clear the current handler to avoid memory-leaks.
    return () => {
      if (timeoutHandler.current) {
        clearTimeout(timeoutHandler.current);
        timeoutHandler.current = null;
      }
    };
  }, [session?.jwt, logout]);

  return null;
};
