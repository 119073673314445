import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserLoggedInline } from '@/components/UserLoggedInline';
import { UserLoggedMenu } from '@/components/UserLoggedMenu';
import { UserUnknownMenu } from '@/components/UserUnknownMenu';
import { useAppRouter } from '@/hooks/useAppRouter';
import { useSession } from '@/hooks/useSession';
import { ROUTES_KEYS } from '@/lib/constants';

export type UserCenterProps = {
  /** display mode for the user center */
  display?: 'menu' | 'inline';
  /** callback to be executed before login */
  beforeLogin?: () => void;
  /** callback to be executed before logout */
  beforeLogout?: () => void;
};

export const UserCenter: React.FC<UserCenterProps> = ({ display = 'menu', beforeLogin, beforeLogout }) => {
  const router = useAppRouter();
  const navigate = useNavigate();
  const { isAuthenticated, logout, session } = useSession();

  const onLogin = useCallback(() => {
    if (beforeLogin) {
      beforeLogin.call(undefined);
    }

    navigate(router.resolveUrl(ROUTES_KEYS.LOGIN));
  }, [navigate, router, beforeLogin]);

  const onLogout = useCallback(() => {
    if (beforeLogout) {
      beforeLogout.call(undefined);
    }

    return logout();
  }, [beforeLogout, logout]);

  let userComponent = <UserUnknownMenu onLogin={onLogin} uppercaseText={display === 'inline'} />;
  if (isAuthenticated && session?.user) {
    userComponent =
      display === 'menu' ? (
        <UserLoggedMenu user={session.user} onLogout={onLogout} />
      ) : (
        <UserLoggedInline user={session.user} onLogout={onLogout} />
      );
  }

  return <div className="hover:text-brand-accent z-50 relative">{userComponent}</div>;
};
