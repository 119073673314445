import React, { useEffect } from 'react';
import { ContentWatchItem } from '../../components/ContentWatchItem';
import { useGetContinueWatchingList } from '../../services/use-get-continue-watching-list';
import { withAuth } from '@/hoc/with-auth';

export const ContinueWatchingList = withAuth(() => {
  const { data, isSuccess, refetch } = useGetContinueWatchingList();

  useEffect(() => {
    refetch();
  }, [refetch]);

  return isSuccess && Array.isArray(data) && data.length > 0 ? (
    <Section>
      <ListTitle title="Continuar viendo" />
      <List>
        {data.map((content) => (
          <ContentWatchItem key={content.watchId} item={content} />
        ))}
      </List>
    </Section>
  ) : null;
});

const Section: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <section className="flex flex-col mt-2 mb-8 mx-2 md:mx-4 lg:mx-6 xl:mx-8">{children}</section>
);

const ListTitle: React.FC<{ title: string }> = ({ title }) => {
  return <h2 className="text-gray-100 text-base md:text-lg lg:text-xl xl:text-2xl font-bold mb-2">{title}</h2>;
};

const List: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <ul className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 3xl:grid-cols-7 gap-2 flex-wrap justify-start list-none whitespace-nowrap">
    {children}
  </ul>
);
