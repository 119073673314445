import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import React from 'react';
import { initialNavigation } from './initial-data';
import { SearchBox } from '../SearchBox';
import { Navbar } from '@/components/Navbar';
import axios from '@/lib/axios';
import { CacheDuration } from '@/lib/constants';
import { Navigation as NavigationType } from '@/types/navigation';

export const Navigation: React.FC = () => {
  const { data } = useGetMainNavigation();
  return (
    <Navbar
      transparent
      navigation={data ?? []}
      actionsComponent={
        <div className="flex z-20 md:scale-100 scale-75">
          <SearchBox />
        </div>
      }
    ></Navbar>
  );
};

const useGetMainNavigation = () => {
  return useQuery(['navigation', 'main'], ({ signal }) => getMainNavigation({ signal }), {
    cacheTime: CacheDuration.FIVE_MINUTES,
    placeholderData: initialNavigation,
    staleTime: CacheDuration.NO_CACHE,
  });
};

const getMainNavigation = (config?: AxiosRequestConfig) => {
  return axios
    .get<NavigationType>('strapi/api/navigation/render/main?type=tree', config)
    .then((res) => res.data)
    .then((items) => items.filter((item) => !!item.related?.publishedAt));
};
