import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { authenticate } from '../api/authenticate';

type LoaderParams = {
  providerName: string;
};

export default async function ({ request, params }: LoaderFunctionArgs) {
  const provider = (params as LoaderParams).providerName;
  const searchString = new URL(request.url).search;
  const searchParams = new URLSearchParams(searchString);
  const errorBack = searchParams.has('error') || searchParams.has('error_code');

  if (!provider || errorBack) {
    return redirect('/login');
  }

  try {
    const session = await authenticate(provider, searchString);
    return { provider, session };
  } catch (err) {
    return redirect('/500', {
      status: 500,
    });
  }
}
