import React from 'react';
import { Link } from 'react-router-dom';

export const Hero = () => {
  return (
    <section className="h-auto flex items-center flex-col w-full justify-center">
      <div className="max-w-4xl space-y-2 flex flex-col items-center text-white absolute z-10">
        <h2 className="xl:text-7xl md:text-6xl sm:text-5xl text-xl font-bold text-shadow-xl w-full pb-2 md:pb-4 lg:pb-8">
          <p>Shows, Novelas, Películas,</p>
          <p>Series y Humor Cubano</p>
        </h2>

        <Link
          to={'/login'}
          className="select-none hover:text-gray-900 hover:bg-gray-100 bg-brand-accent text-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-md md:rounded-lg text-xs sm:text-md md:text-xl xl:text-2xl 2xl:text-3xl px-4 lg:px-8 py-2 lg:py-3 2xl:py-5 text-center"
        >
          Ver ahora ¡Gratis!
        </Link>
      </div>

      <div className="relative h-full overflow-hidden max-h-[84rem]">
        {/* Shadows */}
        <div className="absolute w-full lg:h-60 md:h-20 h-1/6 bg-gradient-to-b from-brand z-10"></div>
        <div className="h-1/6 md:h-20 lg:h-28 xl:h-60 bg-gradient-to-t absolute from-brand text-white bottom-0 w-full z-10"></div>

        <img
          className="-z-10 flex w-full object-fill opacity-60"
          alt="Cubaflix TV"
          srcSet="https://static.cubaflix.com/home/cubaflix_home_480.png 480w,
              https://static.cubaflix.com/home/cubaflix_home_1080.png 1080w,
              https://static.cubaflix.com/home/cubaflix_home_2k.png 2048w,
              https://static.cubaflix.com/home/cubaflix_home_qhd.png 2560w,
              https://static.cubaflix.com/home/cubaflix_home_4k.png 3840w"
          src="https://static.cubaflix.com/home/cubaflix_home_2k.png"
        />
      </div>
    </section>
  );
};
