import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSession } from './hooks/useSession';

export const Protected: React.FC = () => {
  const { isAuthenticated } = useSession();
  const location = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        to="/login"
        state={{
          from: location,
        }}
      />
    );
  }

  return <Outlet />;
};
