import React, { useCallback } from 'react';
import { Search } from 'react-feather';
import { useDebouncedCallback } from 'use-debounce';

type SearchBoxProps = {
  value: string;
  debounceTime: number;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
};

export const SearchBox: React.FC<SearchBoxProps> = ({ value, debounceTime, onChange, onSubmit }) => {
  const handleChange = useDebouncedCallback(onChange, debounceTime);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const target = event.target as typeof event.target & { q: { value: string } };
      onSubmit(target.q.value);
    },
    [onSubmit],
  );

  return (
    <div className="relative focus-within:text-gray-400 cursor-pointer text-gray-200 md:text-sm md:font-medium w-40 lg:w-auto">
      <span className="absolute flex items-center inset-y-0 left-2 text-white">
        <Search className="w-5 h-5" />
      </span>
      <form onSubmit={handleSubmit}>
        <input
          defaultValue={value}
          onChange={(e) => handleChange(e.target.value)}
          type="search"
          name="q"
          placeholder="Búsqueda"
          autoComplete="off"
          className="py-2 bg-transparent pl-8 pr-4 border border-gray-100 w-full rounded-md focus:border-brand-accent focus:outline-none focus:text-white"
        />
      </form>
    </div>
  );
};
