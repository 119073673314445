import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Thumbnail } from '../Thumbnail';
import { withClickEvent } from '@/hoc/with-click-event';
import { Content as ContentItemType } from '@/types/catalog';

type ContentItemProps = {
  item: ContentItemType;
  linkTo: string;
  fromPage?: string;
};

const LinkEvent = withClickEvent(Link);

export const ContentItem: React.FC<ContentItemProps> = ({ item, linkTo, fromPage }) => {
  const { title, image } = item;

  return (
    <Wrapper>
      <article className="w-full relative rounded-md">
        <LinkEvent
          className="block w-full h-full"
          to={linkTo}
          title={title}
          state={{
            fromPage: fromPage,
          }}
          clickevent={{
            action: 'click',
            category: 'Content',
            fromPage,
            label: title,
          }}
        >
          <Thumbnail image={image} className="rounded-md w-full h-auto" />
        </LinkEvent>
      </article>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  box-sizing: border-box;
  scroll-snap-align: start;
  position: relative;
  transition: all 0.5s ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.1);
    z-index: 1;
  }
`;
