import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { LogOut as LogoutIcon, User as UserIcon } from 'react-feather';
import { UserIdentity } from '@/types/app';

type UserLoggedMenuProps = {
  user?: UserIdentity;
  onLogout: () => void;
};

export const UserLoggedMenu: React.FC<UserLoggedMenuProps> = ({ user, onLogout }) => {
  return (
    <Menu as="div" className="relative inline-block text-left select-none">
      <Menu.Button className="inline-flex items-center rounded-md px-4 py-2 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <UserIcon className="w-5 h-5 mr-1" />
        <span className="hidden lg:block">Mi Cuenta</span>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md text-base text-brand-dark bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
          <div className="p-1">
            <Menu.Item>
              <div className="flex flex-col items-center py-2 space-y-2">
                <div className="flex-0">
                  <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                    <svg
                      className="absolute w-12 h-12 text-gray-400 -left-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
                {user && <h5 className="text-gray-600">{user.name}</h5>}
              </div>
            </Menu.Item>
          </div>
          <div className="p-1">
            <Menu.Item>
              <button onClick={onLogout} className="w-full rounded-md p-2 text-center hover:text-brand-accent">
                <LogoutIcon className="h-6 w-6 mr-2 inline-flex items-center" />
                <span>Cerrar sesión</span>
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
