import { useEffect } from 'react';

export function useKeyPress(targetKey: string, handler: (evt: KeyboardEvent) => void) {
  // If pressed key is our target key then set to true
  function downHandler(evt: KeyboardEvent) {
    if (evt.key === targetKey) {
      handler(evt);
    }
  }

  // If released key is our target key then set to false
  const upHandler = (evt: KeyboardEvent) => {
    if (evt.key === targetKey) {
      handler(evt);
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler]);
}
