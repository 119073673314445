import React, { useCallback, useEffect } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import { CookieConsent } from '@/components/CookieConsent';

export const InformCookieConsent = () => {
  // When first mount, check if the cookie was accepted before.
  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === 'false') {
      onDeclineCookies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAcceptCookies = useCallback(() => {
    // TODO: Nothing to do at the momennt.
    // Send GA event.
  }, []);

  // Decline all the cookies.
  const onDeclineCookies = useCallback(() => {
    // TODO: Nothing to do at the momennt.
    // Cookies.remove('_ga');
    // Cookies.remove('_gat');
    // Cookies.remove('_gid');
  }, []);

  return (
    <div>
      <CookieConsent handleAcceptCookie={onAcceptCookies} handleDeclineCookie={onDeclineCookies} />
    </div>
  );
};
