import React from 'react';
import { LoadingMessage } from '../LoadingMessage';

type LazyLoaderProps = {
  children: React.SuspenseProps['children'];
};

export const LazyLoader: React.FC<LazyLoaderProps> = ({ children }) => {
  return <React.Suspense fallback={<LoadingMessage />}>{children}</React.Suspense>;
};
