import React from 'react';
import { useSession } from '@/hooks/useSession';

type WithAuthProps = {
  // Support for future behaviours.
};

export function withAuth<T extends WithAuthProps = WithAuthProps>(WrappedComponent: React.ComponentType<T>) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithAuth = (props: Omit<T, keyof WithAuthProps>) => {
    const { isAuthenticated } = useSession();

    if (!isAuthenticated) {
      return null;
    }

    return <WrappedComponent {...(props as T)} />;
  };

  ComponentWithAuth.displayName = `withAuth(${displayName})`;

  return ComponentWithAuth;
}
