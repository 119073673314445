import React from 'react';
import styled from 'styled-components';

export const ContentItemLoader: React.FC = () => {
  return (
    <Wrapper>
      <article className="w-full relative">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-3 py-1">
            <div className="h-2 bg-brand-light rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-brand-light rounded col-span-2"></div>
                <div className="h-2 bg-brand-light rounded col-span-1"></div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-brand-light rounded col-span-1"></div>
                <div className="h-2 bg-brand-light rounded col-span-2"></div>
              </div>
              <div className="h-2 bg-brand-light rounded"></div>
            </div>
          </div>
        </div>
      </article>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  box-sizing: border-box;
  scroll-snap-align: start;
  position: relative;
`;
