import { Navigation } from '@/types/navigation';

export const initialNavigation: Navigation = [
  {
    collapsed: false,
    externalPath: null,
    id: 1,
    items: [],
    menuAttached: false,
    order: 1,
    path: '/',
    related: null,
    title: 'Inicio',
    type: 'INTERNAL',
    uiRouterKey: 'inicio',
  },
];
