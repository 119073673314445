import { Params, generatePath } from 'react-router-dom';
import { ROUTES, ROUTES_KEYS } from './constants';

type RouterProps = {
  baseURL: string;
};

export type Router = {
  resolveImageUrl: (imageUrl: string) => string;
  resolveApiUrl: (url: string) => string;
  resolveUrl: (name: ROUTES_KEYS, params?: Params<string>) => string;
};

export const createRouter = ({ baseURL }: RouterProps): Router => {
  const resolveApiUrl = (url: string): string => {
    return `${baseURL}${url}`;
  };

  const resolveImageUrl = (imageUrl: string) => {
    return imageUrl.startsWith('http') ? imageUrl : `${baseURL}${imageUrl}`;
  };

  const resolveUrl = (name: ROUTES_KEYS, params: Params<string> | undefined = undefined): string => {
    return generatePath(ROUTES[name], params);
  };

  return Object.freeze({
    resolveApiUrl,
    resolveImageUrl,
    resolveUrl,
  });
};
