import React from 'react';
import { Link } from 'react-router-dom';

export const LoginInvitation: React.FC = () => {
  return (
    <section className="w-full text-white bg-brand px-1 md:px-8">
      <div className="container mx-auto pt-4 pb-8 lg:pb-12">
        <div className="flex flex-col justify-center items-center flex-1">
          <h2 className="xl:text-5xl sm:text-4xl text-2xl text-center w-full lg:pb-12 pb-5">
            ...y muchos más contenidos cada semana.
          </h2>
          <Link
            to={'/login'}
            className="select-none hover:text-gray-900 hover:bg-gray-100 bg-brand-accent text-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-md md:rounded-lg text-xs sm:text-md md:text-xl xl:text-2xl 2xl:text-3xl px-4 lg:px-8 py-2 lg:py-3 2xl:py-5 text-center"
          >
            Mira todo el catálogo
          </Link>
        </div>
      </div>
    </section>
  );
};
