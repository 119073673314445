/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { CustomEvent, useCustomEventTracker } from '@/analytics';

type WithClickEventProps = {
  clickevent: CustomEvent;
  onClick?: (...args: any) => any; // clickable component.
};

export function withClickEvent<P>(
  // Then we need to type the incoming component.
  // This creates a union type of whatever the component
  // already accepts AND our extraInfo prop
  WrappedComponent: React.ComponentType<P & WithClickEventProps>,
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithEvent = (props: P & WithClickEventProps) => {
    const track = useCustomEventTracker();
    const { clickevent, onClick } = props;

    const trackClick = useCallback(
      (...args: any) => {
        track(clickevent);
        if (onClick) {
          onClick(...args);
        }
      },
      [clickevent, track, onClick],
    );

    return <WrappedComponent {...props} onClick={trackClick} />;
  };

  ComponentWithEvent.displayName = `withClickEvent(${displayName})`;

  return ComponentWithEvent;
}
