export enum ROUTES_KEYS {
  AUTH_REDIRECT,
  CONTENT,
  E404,
  E505,
  HOME,
  LOGIN,
  PAGE,
  PLAY_VIDEO,
  PLAY_CONTENT,
  WATCH,
  SEARCH,
}

export const ROUTES: { [key in ROUTES_KEYS]: string } = {
  [ROUTES_KEYS.AUTH_REDIRECT]: '/auth/:providerName/redirect',
  [ROUTES_KEYS.CONTENT]: '/:page/:slug',
  [ROUTES_KEYS.E404]: '/404',
  [ROUTES_KEYS.E505]: '/505',
  [ROUTES_KEYS.HOME]: '/',
  [ROUTES_KEYS.LOGIN]: '/login',
  [ROUTES_KEYS.PAGE]: '/:page',
  [ROUTES_KEYS.PLAY_VIDEO]: '/play/:contentId/:videoId',
  [ROUTES_KEYS.PLAY_CONTENT]: '/play/:contentId',
  [ROUTES_KEYS.WATCH]: '/watch/:watchId',
  [ROUTES_KEYS.SEARCH]: '/busqueda',
};

export const STORAGE_KEYS = {
  LAST_PAGE: 'cubaflix-last-viewed-pge',
};

export enum CacheDuration {
  NO_CACHE = 0,
  ONE_MINUTE = 1 * 60 * 1000,
  FIVE_MINUTES = 5 * 60 * 1000,
  THIRTY_MINUTES = 30 * 60 * 1000,
  ONE_HOUR = 60 * 60 * 1000,
  ONE_DAY = 24 * 60 * 60 * 1000,
}
