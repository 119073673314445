import React, { useCallback } from 'react';
import { X as XIcon } from 'react-feather';
import styled from 'styled-components';
import { useKeyPress } from '@/hooks/useKeyPress';
import useLockedBody from '@/hooks/useLockedBody';

export type MenuItem = {
  menuId: string;
  text: string;
};

const Container = styled.div`
  z-index: 999;
  background: linear-gradient(180deg, rgba(2, 11, 18, 1) 0%, rgba(2, 35, 47, 1) 47%, rgba(0, 60, 74, 1) 100%);
  background-attachment: fixed;
`;

type OverlayMenuProps = {
  items: MenuItem[];
  onDismiss: () => void;
  onMenuClicked: (menuItem: MenuItem, index: number) => void;
  userComponent?: React.ReactNode;
};

export const OverlayMenu: React.FC<OverlayMenuProps> = ({ items, onDismiss, onMenuClicked, userComponent }) => {
  const [, setLocked] = useLockedBody(true);

  useKeyPress('Escape', () => onClose());

  const onClose = useCallback(() => {
    setLocked(false);
    onDismiss();
  }, [setLocked, onDismiss]);

  return (
    <Container className="absolute top-0 left-0 w-full h-full min-h-screen text-gray-200 flex flex-col justify-center items-center">
      <div className="flex flex-row absolute top-4 justify-between w-full">
        <div className="absolute left-2">{userComponent}</div>
        <button
          onClick={onClose}
          tabIndex={0}
          className="hover:text-brand-accent focus:text-brand-accent absolute right-4"
        >
          <XIcon className="w-10 h-10" />
        </button>
      </div>
      <ul className="flex flex-col justify-center space-y-8 text-center text-xl font-sans lg:text-2x h-full py-10 overflow-y-auto">
        {items.map((item, index) => (
          <li key={item.menuId}>
            <button
              className="uppercase hover:text-brand-accent cursor-pointer"
              onClick={() => onMenuClicked(item, index)}
            >
              {item.text}
            </button>
          </li>
        ))}
      </ul>
    </Container>
  );
};
