import React from 'react';
import { Facebook, Instagram, Twitter, Youtube } from 'react-feather';
import { Link } from 'react-router-dom';
import { Navigation as NavigationType } from '@/types/navigation';

type FooterProps = {
  navigation: NavigationType;
};

export const Footer: React.FC<FooterProps> = ({ navigation }) => {
  const year = new Date().getFullYear();

  return (
    <footer className="pt-20 pb-8 px-8">
      <div className="flex flex-col space-y-4 text-gray-500 container">
        <ul className="inline-flex list-none space-x-8 text-gray-400">
          <li>
            <a
              href="https://www.facebook.com/cubaflixtv"
              target="_blank"
              rel="noreferrer"
              className="hover:text-[#1877F2]"
            >
              <Facebook />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/cubaflixtv"
              target="_blank"
              rel="noreferrer"
              className="hover:text-[#E1306C]"
            >
              <Instagram />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/cubaflixtv" target="_blank" rel="noreferrer" className="hover:text-[#1DA1F2]">
              <Twitter />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/@cubaflixtv"
              target="_blank"
              rel="noreferrer"
              className="hover:text-[#FF0000]"
            >
              <Youtube />
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@cubaflixtv"
              target="_blank"
              rel="noreferrer"
              className="hover:text-[#25F4EE]"
            >
              <svg viewBox="0 0 256 256" className="w-7 h-7 inline-block">
                <rect fill="none" height="256" width="256" />
                <path
                  className="stroke-gray-400 hover:stroke-[#25F4EE]"
                  d="M168,106a95.9,95.9,0,0,0,56,18V84a56,56,0,0,1-56-56H128V156a28,28,0,1,1-40-25.3V89.1A68,68,0,1,0,168,156Z"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="12"
                />
              </svg>
            </a>
          </li>
        </ul>
        {navigation && navigation.length > 0 && (
          <div className="text-sm inline-flex flex-col space-y-3 md:space-y-0 md:flex-row md:space-x-8">
            {navigation.map((item) =>
              item.type === 'EXTERNAL' ? (
                <a
                  key={item.id}
                  href={item.externalPath ?? '/'}
                  className="hover:text-brand-accent"
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.title}
                </a>
              ) : (
                <Link key={item.id} to={item.path ?? '/'} className="hover:text-brand-accent">
                  {item.title}
                </Link>
              ),
            )}
          </div>
        )}
        <div className="text-xs">
          <span>&copy; {year} CUBAFLIX LLC</span>
        </div>
      </div>
    </footer>
  );
};
