import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { ignorePaths } from '../../analytics/do-not-strack';

export const PageViewAnalytics: React.FC = () => {
  const location = useLocation();

  // Initialize SDKs
  useEffect(() => {
    if (process.env.REACT_APP_GA_ID_TRACKER) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID_TRACKER);
    }

    if (process.env.REACT_APP_FACEBOOK_PIXEL_ID) {
      // eslint-disable-next-line import/no-named-as-default-member
      ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
    }
  }, []);

  useEffect(() => {
    const ignoredPathMatch = ignorePaths.find((ignoredPath) => location.pathname.toLowerCase().startsWith(ignoredPath));
    const allowTrackLocation = ignoredPathMatch === undefined;

    if (allowTrackLocation) {
      // Track Google Analytics
      if (process.env.REACT_APP_GA_ID_TRACKER) {
        ReactGA.send({
          hitType: 'pageview',
          page: location.pathname + location.search,
        });
      }

      // Track Facebook Pixel
      if (process.env.REACT_APP_FACEBOOK_PIXEL_ID) {
        // eslint-disable-next-line import/no-named-as-default-member
        ReactPixel.pageView();
      }
    }
  }, [location]);

  return null;
};
