import axios, { AxiosRequestConfig } from 'axios';
import { SERVICES_BASE_URL } from '@/config';

const getUserToken = (): string | null => {
  try {
    const payload = JSON.parse(localStorage.getItem('app-auth') ?? '{}');
    return payload.jwt ?? null;
  } catch {
    // Nothing.
  }

  return null;
};

function userTokenRequestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  const token = getUserToken();
  const isUserScopedService = config.url?.startsWith('playback/') || config.url?.startsWith('watch/');

  if (token && isUserScopedService) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return config;
}

const axiosInstance = axios.create({
  baseURL: SERVICES_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  // timeout: 2000,
});

axiosInstance.interceptors.request.use(userTokenRequestInterceptor);

export default axiosInstance;
