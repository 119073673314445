import classNames from 'classnames';
import React from 'react';
import { useGetContentList } from './services/use-get-content-list';
import { ContentItem } from '@/components/ContentItem';
import { ContentItemLoader } from '@/components/ContentItemLoader';
import { useAppRouter } from '@/hooks/useAppRouter';
import { ROUTES_KEYS } from '@/lib/constants';

type ContentListProps = {
  // Page's name where list.id lives within
  page: string;
  fromPage?: string; // TODO: Is this really needed?
  // When clicking an item,
  // Use this value in the URL as the page.
  // `/detalles/<id>` or `/peliculas/<id>`, etc.
  pageLinkAlias?: string;
  list: {
    id: number;
    title: string;
  };
  containerClassName?: string;
};

const placeholders = [1, 2, 3];

export const PageContentList: React.FC<ContentListProps> = ({
  page,
  fromPage,
  pageLinkAlias,
  list,
  containerClassName,
}) => {
  const router = useAppRouter();
  const { isLoading, data, error } = useGetContentList(page, list.id);

  if (!isLoading && error) {
    return null;
  }

  return (
    <Section containerClassName={containerClassName}>
      <ListTitle title={list.title} />

      {!data && (
        <List>
          {placeholders.map((index) => (
            <ContentItemLoader key={index} />
          ))}
        </List>
      )}

      {data && (
        <List>
          {data.contents.map((content) => (
            <ContentItem
              key={content.id}
              item={content}
              fromPage={fromPage ?? page}
              linkTo={router.resolveUrl(ROUTES_KEYS.CONTENT, { page: pageLinkAlias ?? page, slug: content.slug })}
            />
          ))}
        </List>
      )}
    </Section>
  );
};

const Section: React.FC<{ children: React.ReactNode; containerClassName?: string }> = ({
  children,
  containerClassName,
}) => (
  <section className={classNames('flex flex-col mt-2 mb-8 mx-2 md:mx-4 lg:mx-6 xl:mx-8', containerClassName)}>
    {children}
  </section>
);

const ListTitle: React.FC<{ title: string }> = ({ title }) => {
  return <h2 className="text-gray-100 text-base md:text-lg lg:text-xl xl:text-2xl font-bold mb-2">{title}</h2>;
};

const List: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <ul className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 3xl:grid-cols-7 gap-2 flex-wrap justify-start list-none whitespace-nowrap">
    {children}
  </ul>
);
