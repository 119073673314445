import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressMark } from '@/components/ProgressMark';
import { Thumbnail } from '@/components/Thumbnail';
import { withClickEvent } from '@/hoc/with-click-event';
import { ContentWatch } from '@/types/watch';

type ContentWatchItemProps = {
  item: ContentWatch;
};

const EventLink = withClickEvent(Link);

export const ContentWatchItem: React.FC<ContentWatchItemProps> = ({ item }) => {
  const { watchId, mark, image, content } = item;

  return (
    <Wrapper>
      <article className="w-full relative rounded-md">
        <EventLink
          className="block w-full h-full"
          to={`watch/${watchId}`}
          title={content.title}
          clickevent={{
            action: 'resume',
            category: 'Content',
            label: content.title,
            watchId,
          }}
        >
          <Thumbnail image={image} className="w-full h-auto block rounded-md" />
        </EventLink>
        {mark && mark.progress > 0 ? <ProgressMark value={mark.progress} /> : null}
      </article>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  box-sizing: border-box;
  scroll-snap-align: start;
  position: relative;
  transition: all 0.5s ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.1);
    z-index: 1;
  }
`;
