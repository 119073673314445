import React from 'react';

export const Devices = () => {
  return (
    <section className="w-full text-white bg-brand-light px-1 md:px-8">
      <div className="flex flex-col lg:flex-row container mx-auto justify-between space-x-4 py-12 lg:py-24">
        <div className="flex flex-col justify-center items-center">
          <h2 className="xl:text-5xl sm:text-4xl text-2xl text-center lg:text-left w-full lg:pb-12 pb-5">
            Disfruta de Cubaflix en todos los dispositivos
          </h2>
          <p className="xl:text-3xl sm:text-2xl text-sm text-center lg:text-left w-full pb-3">
            Mira incontables películas, series, novelas, aventuras, muñequitos y más desde tu celular, tablet, laptop o
            TV.
          </p>
        </div>

        <div className="max-w-2xl p-3">
          <img alt="Dispositivos" src="https://static.cubaflix.com/home/devices.png" />
        </div>
      </div>
    </section>
  );
};
