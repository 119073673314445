import React from 'react';
import { Navigate } from 'react-router-dom';
import { ContinueWatchingList } from '../../containers/ContinueWatchingList';
import { useGetHome } from '../../services/use-get-home';
import { LoadingMessage } from '@/components/LoadingMessage';
import { Seo } from '@/components/Seo';
import { PageContentList } from '@/containers/PageContentList';
import { ApiException } from '@/exceptions/api.exception';

export default function HomePage() {
  const { data, isLoading, error } = useGetHome();

  if (error && error instanceof ApiException) {
    return error.status === 404 ? <Navigate to="/404" /> : <Navigate to="/500" />;
  }

  if (!isLoading && !data) {
    return <Navigate to="/404" />;
  }

  if (isLoading) {
    return (
      <div className="pt-8 lg:pt-16">
        <LoadingMessage />
      </div>
    );
  }

  return (
    <div className="pt-20">
      <Seo metadata={data?.seo} />
      <div className="mx-auto">
        <ContinueWatchingList />

        {data?.contentLists.map((list) => (
          <PageContentList page={data.name} pageLinkAlias="detalles" list={list} key={`home-list-${list.id}`} />
        ))}
      </div>
    </div>
  );
}
