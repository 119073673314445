import React from 'react';
import ReactCookieConsent from 'react-cookie-consent';

type CookieConsentProps = {
  handleAcceptCookie: VoidFunction;
  handleDeclineCookie: VoidFunction;
};

export const CookieConsent: React.FC<CookieConsentProps> = ({ handleAcceptCookie, handleDeclineCookie }) => {
  return (
    <ReactCookieConsent
      acceptOnScroll={false}
      acceptOnOverlayClick={false}
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
      buttonText="Entendido"
      declineButtonText="Rechazar"
      location="bottom"
      disableStyles={true}
      containerClasses="select-none w-full inline-flex flex-col space-y-3 lg:flex-row lg:justify-between lg:space-y-0 items-center p-4 text-gray-200 fixed bottom-0 left-0 bg-brand-dark text-center z-[1000]"
      buttonClasses="text-gray-900 bg-gray-100 hover:bg-brand-accent hover:text-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2 lg:py-2.5 text-center inline-flex items-center justify-center sm:justify-start mr-2 mb-2 w-full sm:w-auto"
      declineButtonClasses="text-gray-400 hover:bg-brand-accent hover:text-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2 lg:py-2.5 text-center inline-flex items-center justify-center sm:justify-start mr-2 mb-2 w-full sm:w-auto"
    >
      <span>
        Utilizamos cookies para mejorar la aplicación y la experiencia de usuario. Vea nuestras{' '}
        <a
          href={'https://help.cubaflix.com/page/politicas-de-cookies'}
          target="_blank"
          className="text-brand-accent hover:underline hover:text-white"
          rel="noreferrer"
        >
          políticas de cookies
        </a>
        ,{' '}
        <a
          href={'https://help.cubaflix.com/page/politicas-de-privacidad/'}
          target="_blank"
          className="text-brand-accent hover:underline hover:text-white"
          rel="noreferrer"
        >
          políticas de privacidad
        </a>{' '}
        y los{' '}
        <a
          href={'https://help.cubaflix.com/page/terminos-y-condiciones'}
          target="_blank"
          className="text-brand-accent hover:underline hover:text-white"
          rel="noreferrer"
        >
          términos de uso
        </a>
        .
      </span>
    </ReactCookieConsent>
  );
};
