import { ApplicationException } from './application.exception';

export class ApiException extends ApplicationException {
  public constructor(message: string, public readonly status: number) {
    super(message);
  }

  public get isClient(): boolean {
    return this.status >= 400 && this.status <= 499;
  }
}
