import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import React from 'react';
import { Footer as FooterComponent } from '@/components/Footer';
import axios from '@/lib/axios';
import { CacheDuration } from '@/lib/constants';
import { Navigation as NavigationType } from '@/types/navigation';

export const Footer = () => {
  const { data } = useGetFooterNavigation();

  return <FooterComponent navigation={data ?? []} />;
};

const useGetFooterNavigation = () => {
  return useQuery(
    ['navigation', 'footer'],
    ({ signal }) => {
      return getFooterNavigation({ signal })
        .then((navigation) => navigation.sort((a, b) => a.order - b.order))
        .then((sortedNavigation) => {
          return sortedNavigation.filter(
            (item) => item.type === 'EXTERNAL' || (item.related && item.related.publishedAt),
          );
        });
    },
    {
      cacheTime: CacheDuration.ONE_HOUR,
      staleTime: CacheDuration.THIRTY_MINUTES,
    },
  );
};

const getFooterNavigation = (config?: AxiosRequestConfig) => {
  return axios
    .get<NavigationType>('strapi/api/navigation/render/footer?type=tree', config)
    .then(({ data }) => data)
    .then((items) => items.filter((item) => !!item.related?.publishedAt || item.type === 'EXTERNAL'));
};
