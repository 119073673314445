import jwt_decode, { JwtPayload } from 'jwt-decode';

export const isValidToken = (token?: string): boolean => {
  // Token might not be in the expected format,
  // since the user could temper it directly in the local storage.
  if (!token || typeof token !== 'string') {
    return false;
  }

  let decodedPayload: JwtPayload;

  // Try decoding the token.
  try {
    decodedPayload = jwt_decode<JwtPayload>(token);
  } catch {
    // Error decoding the token.
    // It might be corrupted.
    return false;
  }

  // All tokens must have an expiration.
  if (!decodedPayload.exp) {
    return false;
  }

  const isExpired = Date.now() >= decodedPayload.exp * 1000;

  return !isExpired;
};
