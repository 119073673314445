import React from 'react';
import { Helmet } from 'react-helmet';
import { Maybe, SeoMetadata } from '@/types/common';

type SeoProps = {
  metadata?: Maybe<SeoMetadata>;
  children?: React.ReactNode;
};

export const Seo: React.FC<SeoProps> = ({ metadata, children }) => {
  const { metaTitle, canonicalURL, metaDescription, imageUrl } = metadata ?? {};
  const ogImage = imageUrl ?? 'https://static.cubaflix.com/home/cubaflix_home_1080.png';

  return (
    <Helmet
      titleTemplate="%s | Cubaflix - Mira shows, novelas, video clips, películas y humor cubano"
      defaultTitle="Bienvenido"
    >
      <title>{metaTitle}</title>
      <meta property="og:title" content={metaTitle} />
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaDescription && <meta name="og:description" content={metaDescription} />}
      {canonicalURL && <link rel="canonical" href={canonicalURL} />}
      {canonicalURL && <meta property="og:url" content={canonicalURL} />}
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:type" content="image/png" />
      {children}
    </Helmet>
  );
};
