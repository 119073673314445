import * as Sentry from '@sentry/browser';
import React, { useEffect, useRef } from 'react';
import { hotjar } from 'react-hotjar';
import { useLocation } from 'react-router-dom';

export const InstallHotjar: React.FC = () => {
  const hjid = useRef(process.env.REACT_APP_HOTJAR_SITE_ID);
  const hjsv = useRef(process.env.REACT_APP_HOTJAR_VERSION);
  const location = useLocation();

  // Install and initialize the lib
  useEffect(() => {
    if (hjid.current && hjsv.current) {
      hotjar.initialize(+hjid.current, +hjsv.current);
    }
  }, []);

  // Update SPA state for every route change
  useEffect(() => {
    if (hotjar.initialized()) {
      try {
        hotjar.stateChange(location.pathname + location.search);
      } catch (err) {
        // Ignore this error.
        Sentry.captureException(err);
      }
    }
  }, [location]);

  return null;
};
