import { useQuery } from '@tanstack/react-query';
import { getHomePage } from '../api/get-home-page';
import { CacheDuration } from '@/lib/constants';

export const useGetHome = () => {
  const queryKey = ['page', 'home'];
  return useQuery(queryKey, ({ signal }) => getHomePage({ signal }), {
    cacheTime: CacheDuration.ONE_HOUR,
    staleTime: CacheDuration.THIRTY_MINUTES,
  });
};
