import * as React from 'react';
import { retry } from 'ts-retry-promise';

// named imports for React.lazy: https://github.com/facebook/react/issues/14603#issuecomment-726551598
// https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
export function lazyImport<T extends React.ComponentType<unknown>, I extends { [K2 in K]: T }, K extends keyof I>(
  factory: () => Promise<I>,
  name: K,
): I {
  return Object.create({
    [name]: React.lazy(() => retry(() => factory().then((module) => ({ default: module[name] })))),
  });
}

// Usage
// const { Home } = lazyImport(() => import("./Home"), "Home");
