import React from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from './containers/Footer';
import { Navigation } from './containers/Navigation';

export const Layout: React.FC = () => {
  return (
    <>
      <Navigation />
      <Outlet />
      <Footer />
    </>
  );
};
