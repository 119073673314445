import { useQuery } from '@tanstack/react-query';
import { getContentList } from '../api/get-content-list';
import { CacheDuration } from '@/lib/constants';

export const useGetContentList = (pageName: string, listId: number) => {
  const queryKey = ['page-content-list', pageName, listId];

  return useQuery(
    queryKey,
    ({ signal }) => {
      return getContentList(pageName, listId, { signal });
    },
    {
      cacheTime: CacheDuration.ONE_HOUR,
      staleTime: CacheDuration.NO_CACHE,
    },
  );
};
