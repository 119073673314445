import React from 'react';
import { useGetLanding } from '../../services/use-get-landing';
import { PageContentList } from '@/containers/PageContentList';

type ContentListsProps = {
  lists: Array<{
    id: number;
    order: number;
    title: string;
  }>;
};

export const ContentLists: React.FC<ContentListsProps> = ({ lists }) => {
  const { data, isLoading, error } = useGetLanding();

  if (isLoading || error || !data) {
    return null;
  }

  return (
    <div className="w-full text-white bg-brand py-4 md:py-8 lg:py-12">
      {lists.map((list) => (
        <PageContentList
          page={'landing'}
          fromPage={'home'}
          pageLinkAlias="detalles"
          list={list}
          key={`landing-list-${list.id}`}
          containerClassName={'bg-brand mt-0 mb-0'}
        />
      ))}
    </div>
  );
};
