import { AxiosRequestConfig } from 'axios';
import axios from '@/lib/axios';
import { AuthResponse, Session } from '@/types/app';

export const authenticate = (provider: string, params: string, config?: AxiosRequestConfig): Promise<Session> => {
  return axios
    .get(`strapi/api/auth/${provider}/callback${params}`, config)
    .then(({ data }) => data)
    .then(({ user, jwt }: AuthResponse) => {
      const session: Session = {
        jwt,
        provider,
        user: {
          id: user.id.toString(),
          name: user.username,
        },
      };
      return session;
    });
};
