import classNames from 'classnames';
import React from 'react';

type ProgressMarkProps = {
  value: number;
};

export const ProgressMark: React.FC<ProgressMarkProps> = ({ value }) => {
  return (
    <div className="w-full rounded-b-md h-1.5 bg-gray-700 absolute bottom-0 left-0">
      <div
        className={classNames('h-1.5 rounded-bl-md bg-brand-accent', {
          'rounded-br-md': value >= 99,
        })}
        style={{
          width: `${value}%`,
        }}
      ></div>
    </div>
  );
};
